import styled from 'styled-components';

import { StorySection } from '~/src/mdx/components/StorySection/StorySection';
import { withMedia } from '../../../../utils/withMedia';

export const ImageWrapper = styled(StorySection)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${withMedia('--medium')}) {
    overflow: auto;
  }
`;
