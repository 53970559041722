import styled, { css } from 'styled-components';

import Image from '@uc-common/image';
import { StorySection } from '~/src/mdx/components/StorySection/StorySection';

export const MainImageWrapper = styled(StorySection)(
  ({ theme: { tablet, laptop } }) => css`
    max-height: 100px;
    max-width: 177px;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;

    ${tablet()} {
      margin-bottom: 25px;
    }

    ${laptop()} {
      margin-top: 40px;
      margin-bottom: 30px;
    }
  `
);

export const StyledMainImage = styled(Image)`
  max-width: 177px;
  display: block;
`;
