import { css } from 'styled-components';

export const style = ({ theme: { tablet, laptop, desktop } }) => css`
  font-size: 17px;
  max-width: 960px;
  padding-bottom: 80px;
  line-height: 1.6em;
  color: #232a30;

  ${tablet()} {
    line-height: 1.7em;
    padding-bottom: 120px;
  }

  article,
  aside,
  canvas,
  dd,
  figcaption,
  footer,
  form,
  header,
  hgroup,
  li,
  main,
  nav,
  section,
  thead,
  tfoot,
  tbody {
    margin: 0;
    padding: 0;
  }

  address,
  audio,
  blockquote,
  dl,
  fieldset,
  figure,
  hr,
  ol,
  output,
  p,
  ul {
    margin: 0.6em 0 0.7em 0;
  }

  strong {
    color: black;
  }

  a {
    transition:
      color 0.3s ease,
      background-color 0.3s ease;
    color: #3771ff;
    text-decoration: none;

    &:hover,
    &:focus {
      color: #0242df;
    }

    &:visited {
      color: #3771ff;

      &:hover,
      &:focus {
        color: #0242df;
      }

      &:active {
        color: #3771ff;
      }
    }

    &:focus {
      outline: rgba(14, 84, 255, 0.3) solid 2px;
      outline-offset: 2px;
    }

    @media print {
      text-decoration: underline;
    }
  }

  blockquote {
    font-family: var(--font-headings);
    text-align: center;

    p {
      padding-bottom: calc(var(--text-padding-bottom) - 16px);

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  caption,
  figcaption,
  img + em {
    color: #596570;
    font-size: 14px;
    margin: 0;
  }

  code {
    font-family: var(--font-mono);
    font-weight: normal;
    font-size: 13px;
    line-height: inherit;
    box-sizing: border-box;
    background-color: #f0f1f1;
    color: #39424a;
    border-radius: 5px;
    padding: 3px 6px;
    border: 1px solid rgba(2, 25, 30, 0.03);
  }
  code a,
  a code {
    color: inherit;
  }

  pre code {
    border-radius: 8px;
    background-color: #f5f5f7;
    padding: 14px 18px;
    line-height: 24px;
    display: block;
    white-space: pre;
    overflow: auto;
    border: none;
  }

  dd {
    padding-left: var(--text-padding-left);
  }

  figure {
    max-width: 100%;
    text-align: center;
  }
  figure pre {
    text-align: left;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-headings);
    font-weight: 700;
    margin: 1.2em 0 0.7em 0;
    color: #000000;
    text-align: left;

    &[id] {
      scroll-margin-top: var(--navbar-height);
    }

    code {
      font-size: 0.85em;
      font-weight: inherit;
    }

    a {
      text-decoration: none;
      transition: color 0.3s ease;

      code {
        text-decoration: none;
      }

      &,
      &:link,
      &:visited,
      &:active {
        color: #000000;
      }
      &:hover,
      &:visited:hover {
        color: #596570;
      }
      &::before {
        position: absolute;
        content: '#';
        transform: translateX(-0.9em);
        color: transparent;
        transition: color 0.3s ease;
      }
      &:hover::before {
        color: #dfe0e2;
      }
    }
  }

  h1 {
    margin: 0.7em 0 0.4em 0;
    font-size: 34px;
    line-height: 1.4em;
    letter-spacing: -0.025em;
    ${laptop()} {
      font-size: 40px;
    }
    ${desktop()} {
      font-size: 44px;
    }
  }

  h2 {
    font-size: 26px;
    line-height: 1.2em;
    letter-spacing: -0.02em;
    ${desktop()} {
      font-size: 30px;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 1.3em;
    letter-spacing: -0.01em;
    ${tablet()} {
      font-size: 22px;
    }
  }
  h4,
  h5,
  h6 {
    font-size: 16px;
    margin: 1.2em 0 0.4em 0;
  }

  h2 + h3 {
    margin-top: 1.1em;
  }

  hr {
    border: none;
    border-top: 1px solid #f1f2f3;
    margin: 1.5em 0 1.5em 0;

    .compact {
      margin: 0;
      padding: 0;
    }
  }

  img {
    max-width: 100%;
    margin: 1em auto 1.2em auto;
    display: block;
  }

  ol,
  ul {
    padding-left: 2em;

    ol,
    ul {
      padding-bottom: 0;
    }
  }

  li {
    margin-bottom: 0.5em;
    &::marker {
      color: #9da9b5;
    }
  }

  picture {
    max-width: 100%;
  }

  pre {
    max-width: 100%;
    font-family: var(--font-mono);
    white-space: pre-wrap;
    margin: 0.6em 0 1em 0;
  }

  q {
    font-family: var(--font-headings);
    quotes: '“' '”' '‘' '’';
  }

  table {
    font-size: 15px;
    border-collapse: collapse;
    border-spacing: 0;
    border: 0;
    padding: 0;
    margin: 1.2em 0 2.4em 0;
    overflow-x: auto;
    display: block;
  }

  thead {
    border-top: 1px solid var(--color-light-grey);
    th {
      color: #232a30;
      text-align: left;
      font-weight: 500;
    }
  }

  tbody {
    color: #596570;
  }

  tr {
    border-bottom: 1px solid var(--color-light-grey);
  }

  th,
  td {
    padding: 8px;
    padding-right: 14px;
    border: none;

    &:first-child {
      padding-left: 0;
    }
  }

  video {
    max-width: 100%;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
  }

  abbr {
    text-decoration: none;
    border-bottom: 1px #000000 dashed;
  }
`;
