import styled from 'styled-components';

import { StorySection } from '~/src/mdx/components/StorySection/StorySection';

export const BreadcrumbsContainer = styled(StorySection)`
  margin: 20px auto 0 0;
`;

export const BreadcrumbsList = styled.ul`
  display: flex;
  padding-left: 0px !important;
  list-style: none;
  width: 100%;
`;

export const BreadcrumbsListItem = styled.li`
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  color: #3771ff;
  padding-left: 0px;

  a {
    color: inherit;
    text-decoration: none;
  }

  ::after {
    content: '/';
    margin: 0 8px;
  }

  :first-child::first-letter {
    text-transform: uppercase;
  }

  :last-child:after {
    content: none;
  }
`;
