/* Vendors */
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { Container } from '@uc-common/container';

/* Components */
import { SiteLayout } from '~/src/components/Layout/SiteLayout';
import { Breadcrumbs } from '~/src/mdx/components/Breadcrumbs';
import { MainImage } from '~/src/mdx/components/MainImage';
import { Image } from '~/src/mdx/components/Image';

/* Utils */
import { CDN_BASE } from '~/src/constants/api';
import { getSchema } from '~/src/mdx/templates/learning/helpers/getSchema';
import { getArticleMeta } from '../helpers/utils';
import { ArticleFinalCTA } from './styles/ArticleTemplate.styles';

/* Styles */
import { style } from './style';

const StyledContent = styled(Container)`
  ${style}
`;

/* Page */
const ArticleTemplate = ({ location, data: { mdx } }) => {
  const { pathname, href } = location;
  const articleMeta = getArticleMeta(mdx);

  const jsonLDMap = ({ id, sameAs }) => ({
    '@type': 'Thing',
    name: 'what',
    id,
    sameAs,
  });

  const { title, header, description, image, thumbnails, dateCreated, keywords, mentions, about } =
    articleMeta.frontmatter;

  const metaImage =
    image && `${CDN_BASE}/${image}/-/progressive/yes/-/format/auto/-/quality/normal/`;

  const meta = {
    title,
    header,
    description,
    image: metaImage,
    schema: getSchema({
      image: metaImage,
      thumbnailUrl: thumbnails?.map(
        (uuid) => `${CDN_BASE}/${uuid}/-/progressive/yes/-/format/auto/-/quality/normal/`
      ),
      url: href,
      headline: title,
      description,
      dateCreated,
      keywords,
      ...(mentions && { mentions: mentions.map(jsonLDMap) }),
      ...(about && { about: about.map(jsonLDMap) }),
    }),
  };
  const analyticsPostfix = pathname.split('/learning/')[1].replaceAll('/', '-').slice(0, -1);

  return (
    <SiteLayout meta={meta} pathName={pathname}>
      <StyledContent>
        <Breadcrumbs pathname={pathname} />
        {meta.title && <h1>{meta.header || meta.title}</h1>}
        <MDXProvider
          components={{
            img: Image,
          }}
        >
          <MDXRenderer>{articleMeta.code}</MDXRenderer>
        </MDXProvider>
      </StyledContent>

      {image && <MainImage pathname={image} />}
      <ArticleFinalCTA
        btnSignUpProps={{
          'data-analytics': `btn_sign-up-learning-${analyticsPostfix}`,
        }}
        btnScheduleDemoProps={{
          'data-analytics': `btn_schedule-demo-learning-${analyticsPostfix}`,
        }}
      />
    </SiteLayout>
  );
};

export default ArticleTemplate;

export const pageQuery = graphql`
  query ($id: String!, $sourceName: String!) {
    mdx(id: { eq: $id }) {
      ...ArticleBase
      ...ArticleExports
      ...ArticleCode
    }
    allMdx(
      filter: { exports: { disabled: { ne: true } }, fields: { sourceName: { eq: $sourceName } } }
    ) {
      edges {
        node {
          ...ArticleBase
          ...ArticleExports
        }
      }
    }
  }
`;
