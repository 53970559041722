import React from 'react';

import { CDN_BASE } from '~/src/constants/api';
import { StorySection } from '~/src/mdx/components/StorySection/StorySection';
import { MainImageWrapper, StyledMainImage } from './MainImage.styles';

export const MainImage = ({ pathname }) => {
  const mainImagePath = `${CDN_BASE}/${pathname}/-/scale_crop/177x100/smart/-/format/auto/-/quality/normal/`;
  return (
    <StorySection>
      <MainImageWrapper>
        <StyledMainImage src={mainImagePath} />
      </MainImageWrapper>
    </StorySection>
  );
};
