import React from 'react';

import { Link } from '~/src/components/Link';

import {
  BreadcrumbsContainer,
  BreadcrumbsList,
  BreadcrumbsListItem,
} from './styles/Breadcrumbs.styles';

export const Breadcrumbs = ({ pathname }) => {
  const directories = pathname.split('/').filter((item) => item !== '');

  return (
    <BreadcrumbsContainer>
      <BreadcrumbsList>
        {directories.map((crumb, index) => (
          <BreadcrumbsListItem key={crumb}>
            {index === 0 ? (
              <Link to="/learning/">{crumb}</Link>
            ) : index === directories.length - 1 ? (
              `#${crumb}`
            ) : (
              crumb
            )}
          </BreadcrumbsListItem>
        ))}
      </BreadcrumbsList>
    </BreadcrumbsContainer>
  );
};
