export const getSchema = ({
  thumbnailUrl,
  url,
  headline,
  description,
  dateCreated,
  keywords,
  mentions,
  about,
}) => ({
  '@context': 'https://schema.org/',
  '@type': 'LearningResource',
  teaches: 'what',
  competencyRequired: 'which',
  educationalUse: 'Self-education',
  url,
  thumbnailUrl,
  name: headline,
  description,
  dateCreated,
  datePublished: dateCreated,
  dateModified: dateCreated,
  audience: {
    '@type': 'EducationalAudience',
    educationalRole: 'Programmer',
    sameAs: 'https://www.wikidata.org/wiki/Q5482740',
  },
  mentions,
  about,
  author: {
    '@type': 'Person',
    givenName: 'Barbara',
    familyName: 'Sawala',
    url: 'https://uploadcare.com/blog/author/barbara-sawala/',
    image:
      'https://ucarecdn.com/dd79deaf-b76d-4b6a-b058-b0b985570960/-/format/auto/-/quality/smart/-/preview/200x200/',
    worksFor: {
      '@type': 'Corporation',
      name: 'Uploadcare',
      url: 'https://uploadcare.com/company/',
    },
  },
  creator: {
    '@type': 'Person',
    givenName: 'Barbara',
    familyName: 'Sawala',
    url: 'https://uploadcare.com/blog/author/barbara-sawala/',
  },
  publisher: {
    '@type': 'Corporation',
    url: 'https://uploadcare.com',
    name: 'Uploadcare',
    description:
      'Uploadcare is a robust file API for uploading, managing, processing, rendering, optimizing and delivering users’ content.',
    logo: 'https://ucarecdn.com/7e668407-0c26-498c-9ea0-8709a5343569/uploadcare-logo.png',
  },
  keywords,
});
